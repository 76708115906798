import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Generator from './Generator';
import Gallery from './Gallery';
import Header from './Header';
import "./Gallery.css"; // Import your Gallery CSS here
import Footer from './Footer';
import About from './About';
import Documentation from './Documentation';

function App() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch image filenames from your API or directory
    fetchImages().then((imageData) => {
      setImages(imageData);
    });
  }, []);

  // Fetch image filenames from your API or directory
  const fetchImages = async () => {
    try {
      const response = await fetch('http://127.0.0.1:8000/api/get_image/${image}`}'); // Replace with your API endpoint
      const data = await response.json();
      return data.imageFilenames || [];
    } catch (error) {
      console.error('Error fetching images:', error);
      return [];
    }
  };

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Generator />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/about" element={<About />} /> {/* Add the About route */}
		<Route path="/documentation" element={<Documentation />} />
      </Routes>
	  <Footer />
    </Router>
  );
}

export default App;
