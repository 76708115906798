import React, { useState, useEffect } from 'react';
import './Gallery.css';
const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch image filenames from your FastAPI server
    fetchImages().then((imageData) => {
      setImages(imageData);
    });
  }, []);

  // Fetch image filenames from your FastAPI server
  const fetchImages = async () => {
    try {
      const response = await fetch('https://5ud8o4ku3nv68r-4000.proxy.runpod.net/api/get_images'); // Replace with the correct URL of your FastAPI server
      const data = await response.json();
      return data || [];
    } catch (error) {
      console.error('Error fetching images:', error);
      return [];
    }
  };

  return (
    <div className="gallery">
      <h2>Generated Images Gallery</h2>
      <div className="image-grid">
        {images.map((image, index) => (
          <img
            key={index}
            src={`https://5ud8o4ku3nv68r-4000.proxy.runpod.net//api/get_image/${image}`} // Replace with the correct URL of your FastAPI server
            alt={`Image ${index}`}
            className="gallery-image"
          />
        ))}
      </div>
    </div>
  );
};

export default Gallery;
