import React, { useState } from 'react';
import axios from 'axios';
import './Generator.css'; // Import the CSS file

const Generator = () => {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');

  const generateImage = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`https://5ud8o4ku3nv68r-4000.proxy.runpod.net/?prompt=${prompt}`);
      setImage(response.data);
    } catch (error) {
      console.error('Error generating image:', error);
    }

    setLoading(false);
  };

  return (
    <div className="generator">
      <h1>Revolutionary Retromimesis</h1>
	  <h2 className="heading">
        70年小人书： A Retrofuturist Image Generator based on Vintage Chinese Comics
      </h2>
	  <p className="text"> These comic books recall an era of rigid, optimistic ideology and a time of deep national trauma. But they also reveal a moment of unprecedented mobility and the promise of modernity. These comics, with their dynamic illustrations and “true stories,” are the result of a revolutionary form of collective, mimetic cultural production in which what mattered was not the output or author but the effect. This project, made using entirely open-source tools, revives that form of cultural production for the AI age. What possibilities will emerge when we use it to imagine a pluralistic collective, collaborative life? Try it out.
	  </p>
	  <p>
        Click on the generate button to generate an image from a Stable
        Diffusion 1.5 model trained on vintage Chinese comics. This is an
        all-ages friendly, SFW model. If NSFW content is detected, the
        generated image will return as a black square. If that happens, just
        try again.{" "}
      </p>
      <div className="input">
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          style={{ width: '350px' }}
          placeholder="Enter a prompt..."
        />
        <button onClick={generateImage} className="button">
          Generate
        </button>
      </div>
<p></p>
      {loading ? (
        <div>Loading...</div>
      ) : image ? (
        <div>
          <img
            src={`data:image/png;base64,${image}`}
            alt="Generated"
            style={{ boxShadow: 'lg' }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Generator; // Use "export default" here
