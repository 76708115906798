// About.js

import React from 'react';
import './About.css'; // Import the CSS file
const About = () => {
  return (
    <div className="about">
      <h2>About Retromimesis</h2>
      <p>I lived in Beijing for four years. In that time, the city became my home. I learned how to take the subway anywhere, roamed through a city where modernity and history existed in a jumbled mix. Here were the hutongs near Dongzhimen; and there were the towering skyscrapers of the CBD; and my home, which I loved the most, was just on the edge of the fourth ring road, along the margin where the city’s density dissipated. Beyond the fourth ring road were the suburbs, the city’s outskirts, chaotically developed but full of vibrant life.</p>
	  <p>In my last year there, I got an electric motorcycle. Its name was Little Blue Niu. Niu means cow, but it’s also the word for a brand of sport scooter. I imagined myself riding a little blue cow, a cow that can race along at 50kmph, out away from the skyscrapers and into the dusty countyside, right out towards the mountains that I looked to, longingly, every day in the office. That’s when I could see them, of course; half the year they were invisible, cloaked in mist, and by mist, I mean pollution. Mist, 雾霾, is a kind of tongue-in-cheek doublespeak: it means, simply, “mist”; but it’s become synonymous with pollution, with the government’s attempt to hide what runaway development has brought to this world. When the mist is bad, people look over the tops of their masks with tired eyes, so tired of waiting for the next day with blue sky. But here we are, in it together, and maybe the government will finally get the factories to turn on their scrubbers. Maybe there will be enough alternative energy that we won’t have to burn coal and natural gas, won’t go cold when it’s too smoggy to dock the tanker in Tianjin.</p>
	  <p>They tell us it’s growing pains, but as the smoggy days grow darker and worse, as the rich buy palatial homes in newly developed suburbs, as the exurbs suffer and urban life becomes claustrophobic, it seems like the kind of deep ache that comes from sickness. That comes from an inbalance, coursing and deep and essential. That needs to be treated with healthy living before it grows into something cancerous. We turn on our air purifiers, we sigh, we at least have each other. But it’s hard to watch old grandmothers walking through the smog, hard to watch kids playing in it, and sometimes even the pet dogs are coughing. We just have to hope that it gets better. We just have to make it a little better. Just get past this obstacle, eat the bitterness until we make it out to the other side. But I begin to wonder if there is one.</p>
	  <p>In my last weeks in the city, I took a liking to an old park out beyond the Fourth Ring. It was beautiful, in a grim, abandoned kind of way. It was also gorgeous, in some parts, in its own right: winding paths through landscaped gardens, visually exciting but oddly random modern sculptures, stands of tall trees with their trunks painted white to protect them from insects. It was winter, just on the verge of Spring Festival, and everywhere lay the traces of work hastily abandoned: potting soil in piles, a cardboard sign scribbled with an apology note, planting beds waiting for their seedlings. Everyone goes home for Spring Festival. This year, I was mourning that I would leave mine. </p>
	  <p>I remember going in the late afternoon one cold weekday. I parked my bike in the parking lot, stowed my helmet beneath the seat, locked the front wheel, and off I went. Walking felt slow, creaky. I had been sick several times that year, in and out of the hospital, and the combination of my 9-9-6 with illness had left me totally spent. I was twenty-eight at the time, too young to feel like this, I thought. But after those hospitalizations, when I went to see my friend off to the airport, I ended up catching a flu that laid me up in bed for weeks. I lived off of pocari sweat, a rehydration drink, and soup and herbal tea. I chugged herbal cold medicine like my life depended on it, lay in my sweat for hazy days, completely alone. My friends had left for Spring Festival, headed home no matter where they were from—some to Singapore, some to America, some to Europe. My cats were scheduled to fly home at the end of the month. So I stayed, let the fever burn out, packed up what I had left, readied myself for whatever would come next. I woke up one morning to find a missed call from a California zip code. Again, from New York. The acceptance emails would come in  later. I’d made it to the other side, just about?</p>
	  <p>What would it be like, to go back to that place which was home but was not home? What would it be like, to live again in my mother tongue, to live again where I couldn’t simply melt away into the crowd, one iteration among many, all more or less the same? This is what was on my mind as I made my way, for the first time, to the other side of the park, to the far end where some mysterious buildings stood. </p>
	  <p>I inspected the complex of three abandoned buildings. On the walls were sun-bleached posters with upbeat slogans, proclaiming the importance of—of something prosocial, I guess. The windows were cracked, and upon looking inside, I could see cigarettes, beer cans, and graffiti. As I stood there, hand through the window, trying to get a good photo without getting cut, an old woman caught my attention.</p>
	  <p>She was slightly stooped, wearing a padded vest over a sweater and a pair of pants that cut off just above the ankle. On her feet were old-fashioned cloth shoes. She looked like my grandma, like my great grandma, and just as if she was one of my family members—we’re all kind of extended family, for better or worse—she came over, got my attention, and started talking with me. My listening is pretty good, but my speaking is clunky. Words come slow to my tongue if they’re not practiced phrases. I know how to say the most common ones: where I’m from, yes I’m overseas Chinese, what I do here, etcetera. But I wasn’t prepared for what came next.</p>
	  <p>She asked me, do you know who Mao is? Do you know what this place used to be? I say, no, I—I—	</p>
	  <p>She talks fast. I say, I’m overseas Chinese, can you talk slower? She sort of accepts the request. She seems more amenable, now, to my disorientation. I am forgiven for not remembering those days, for not knowing the living history that lies now in ruins. She talks to me with the urgency of someone who needs to let something out, lest it be forgotten, lest it be unsaid. I asked, what is this place? She said, it used to be a community center. Kids like me, these days, don’t remember the old days, in the 60’s and 70’s. Back then, when Mao was around, things were different. The center hosted all kinds of activities and sports, and everyone got together there. There were meals, and games, and—now! Now, people forgot—people didn’t remember what it used to be. Everything went downhill after that, she said. I don’t remember the  rest of our conversation so clearly. We walked together for about forty-five minutes, before it got dark, before she had to call her little dog with its blinking LED collar to heel. We talked about a lot: about China, about America, about being overseas, about pets, about the ways things have changed, what she thought about all this. It’s hazy, foggy in my memory. But I do remember her energy, her insistence, her demand that those days be remembered, that the energy and dynamism of a tight community be celebrated, held precious.</p>
	  <p>It wouldn’t be until years later that I would really understand what she meant. Later, I would learn more about the Cultural Revolution, and not from the perspective that I was taught in an American school. I read more about what that time was like from the people who had lived it—going through my grandmother’s old books, reading The Art of Cloning by Pan Laikwan. It was a difficult, tumultuous time. May grave injustices were committed, including the murder of my grandmother’s father, the village head in Jiangmen. But it was also a time of vibrant cultural production, experimentation, of great movement, of long train journeys, of finally being able to leave the village for the world. </p>
	  <p>In Pan Laikwan’s The Art of Cloning, the author describes the role of cultural production during the Cultural Revolution. It was decentralized at first before, in ‘68-69, the government started to function again. But it wasn’t just brainwashing. It wasn’t about controlling the narrative. It was a Maoist dedication to media—the production and circulation of cultural material that could reach the masses by moving the individual, a constant production in service not to the individual author, but to the collective process of remaking the world. I now understand what that old grandma told me, the dynamism, the excitement, the sense of potential. I also now understand her disdain for the current state of affairs, for the stifling system of economic progress that has left everyone exhausted and gasping for air. Nobody having kids. Everyone who can move, does. Families split again, not by economic uncertainty, but the sheer unliveability of some mega-cities. The unliveability of ruthless competition at grand scale. This was not the future anyone dreamed of.</p>
	  <p>In Lawrence Lek’s Sinofuturism, Lek postulates that Chinese culture might be considered a form of artificial intelligence: many nodes, connected tightly together, each iterating, working ceaselessly in a dangerous, precarious world to keep going, focus, push for whatever might come next, looking for a future beyond this, beyond work—but for what? I guess, to keep computing, to keep voraciously devouring all information, to synthesize, to, collectively, be. But what is being, even, in our moment of global social, ecological, political and psychological crisis?</p>
      <p>I think, to refer to the ideas in Bernard Stiegler’s edited collection Bifurcate: There is No Alternative, to face the truth of our moment. To let the mist of possibility and uncertainty dissipate, reveal what is truly occurring. Let the cold light of morning leave our moment perfectly illuminated. The current, anthropic developmental model has brought wealth and comfort to a precious few, and disaster to many. It has stolen away our sense of self, created us into automatons, sculpted us into creatures that exist during working hours and dissipate at rest. It threatens everything that we need to survive, places Western ontological dualism as the example of thought that we ought all to aspire to, subordinates other ways of being. It cuts us off into neat atomized little liberal subjects. It makes us into nothing. We do not value children or the elderly, because they do not work. We do not value idleness, those generative moments of quiet which we need to be. Anything to numb the pain: information addiction, gaming addiction, drugs, gambling.</p>
	  <p>But it’s never hopeless. There’s always hope.</p>
	  <p>In 2023, I was first introduced to Indigenous epistemologies—concurrently, through Arturo Escobar’s Designs for the Pluriverse, and through Jason Lewis’s indigenous futures class. During this time, I’ve found that there are many parallels between East Asian and Indigenous American ways of being. Time is not necessarily linear; it is cyclical; there exist things beyond what we know here, in this life. Being does not mean acquiring fame, wealth, narcissism. It is tied to who we are, the people we love, the land that sustains us, those who have come before, and those who will come after us. Escobar uses the framework of “relationality” to describe cultures in which the liberal self of political economy is not the model for being. Instead, being is an experience of relation. For some Indigenous cultures, being is intimately tied to the land that these cultures have grown with over millennia: existing in a network of mutual respect with ties within the community and to the land and the creatures that populate it, to its precious essence. Chinese culture is different: there are special places, and some 本地人, persons originally from a certain place, have deep ties to that place’s land. Others don’t, having left by sea or land and traveled to wherever they might be now. Chinese culture is a culture of diaspora, of fated encounters and moments out of time. It is relational in that, while families may be scattered across the world, while the people we meet might be very different to us, we are tied by something that is impossible to articulate, a thrumming bond that exists somewhere in language and story and conversation. We are tied to each other. We belong to each other. All of us. No matter how different we might be, when we choose to embrace others, we connect: we feel that energy that moves through all of us. When we connect, we all become.</p>
	  <p>The idea of a pluriverse is a world in which many worlds exist, together, engaging in ethical encounters based on mutual respect and humanity. This idea of many worlds existing comes from those who know and love their world: the Indigenous peoples of Chiapas, who successfully and nonviolently secured control over the nominally Mexican province and now govern it semi-autonomously.  It is a world in which the only thing that doesn’t exist is the desire to swallow the world whole, to search for value, to aggregate wealth and power and wield it as aegis. It is a world in which the good life, a concept integral to some American indigenous cultures, is paramount. When we face our current moment, we must also remember it is not about purity, not about correctness, not about perfection: this is a moment in which we must come together, belong to each other, and take the steps into a better future together. Where we connect and become.</p>
	  <p>I want to imagine that whatever comes next after this is beautiful. I want to imagine that everything which occurs after the cataclysm we can all feel coming leads to survival, not extinction. But I cannot see the path there, not yet. I am no visionary. I hope, one day, vision might visit me—that I might glimpse what is to come, however brief, to fall out of time and into recursion and breathe so deep that I become air—but I don’t know if it will. I do think that as part of my generation, I have a responsibility to do all I can to help a better future bloom, even if I’ll never see it.</p>
	  <p>When I talked with that old grandma, so many years ago now, she asked me what my name was. Did I have a Chinese name? Yes, it’s 慧娜. 惠娜, she repeated. I said no, not 德惠 的惠: that means well-behaved woman. I am not well-behaved. I say, 聪明的慧。My grandmother gave my name to me, I said. Oh, is your grandmother from Hong Kong? Yes, I answer. I know that this is what she will say next, because my name is a common name in Southern China. It means “woman brilliant as a comet.” I wouldn’t describe myself that way; I’m fumbling, plagued by inconsistency, terrible at paperwork and planning. I can’t make a doctor’s appointment for my life, and my house is in total disarray. I’m disorganized. I don’t function that well. I fucking crash, constantly, like a computer with a RAM problem. But, like a comet, I do keep coming back. I loop, lonely, disconnected from the world, spaced out into ideas, into the internet, into making. I come out of it, dust myself off, and hurtle towards the next thing. I come back to this earth, this place I love.</p>
	  <p>My most recent thing has been working with AI and machine learning tools. I love AI; did you know that in Chinese, the word “ai” means “love?” I love its affordances; I love how it means my computer and I are dreaming together, refining an image of what might be. And I think about what it means to face our current moment—I think about what we might need to create the movement that realizes a beautiful pluriverse. A world in which we all fit, in which we all belong to each other, and this precious planet, and all the life on it. How can we harness the energy and dynamism of the past and hook it into our modern media machine? How can we generate possibility? I can’t think of a better way to do it than AI. Than love. So with love, I give you this model. I trained it on comic books from that Beijing grandma’s good old days, those days she remembered with such urgency and fondness. These are from books for little children. I hope, from this, we can build something better for our children.</p>
	  <p>Because there is always hope. Dangerous, necessary, beautiful hope.</p>
	  <p>So—go on! Go use it! Imagine what might be! And spread, spread, spread your beautiful imaginary! </p>
    </div>
  );
};

export default About;
