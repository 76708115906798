import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Import the CSS file

const Header = () => {
  return (
    <header className="header">
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
		   <li>
           <Link to="/about">About</Link>
          </li>
		   <li>
           <Link to="/documentation">Documentation</Link>
          </li>
		    <li>
		<a href="https://www.rowenac.com" target="_blank" rel="noopener noreferrer">Main</a>
		  </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
