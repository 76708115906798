import React from 'react';

const Documentation = () => {
  return (
    <div className="documentation">
      <h2>Documentation</h2>
      <p>
        This documentation page contains information about the development and training of this model.
      </p>

      <p>
        This model was natively trained with images scanned from a selection of Chinese comic books dating between 1965-1973. Known as "小人书" or children's bbooks, these iconic booklets were collectively produced and used to teach historical and moral lessons. Commonly "based on a true story," these books represent an interesting development in the history of mass media and cultural production. Though individual authors, editors and illustrators were credited, these pieces of media were created in the service of ideology, not as a means for creating individual distinction. They therefore have an interesting mimetic property, each a copy of others--created with the knowledge of others--for a large audience.
      </p>
	  <p>In creating this model, I had three principles that I wanted to follow: I did not want to violate copyright/harm graphic artists, I did not want to contribute to a closed-source for-profit AI company, and I wanted to use only open-source tools. I used Python, Jupyter Notebook, Stable Diffusion, and the GNU Image Manipulation Program (GIMP), the kohyass LoRA native training script, and the AUTOMATIC1111 Stable Diffusion graphical user interface to complete this project. Unfortunately, due to issues with a security protocol on my computer that could not be resolved through actions in Windows Powershell, I was not able to train the model locally on my own hardware, and had to run the scripts through Google CoLab.</p>

        <p>This is the training configuration:
      </p>
	  <p>[model_arguments]
v2 = false
v_parameterization = false
pretrained_model_name_or_path = "/content/pretrained_model/AnyLoRA.safetensors"
[optimizer_arguments]
optimizer_type = "AdamW8bit"
learning_rate = 2e-6
max_grad_norm = 1.0
train_text_encoder = false
lr_scheduler = "constant"
lr_warmup_steps = 0
[dataset_arguments]
debug_dataset = false
in_json = "/content/fine_tune/meta_lat.json"
train_data_dir = "/content/drive/MyDrive/images"
dataset_repeats = 10
shuffle_caption = true
keep_tokens = 0
resolution = "512,512"
caption_dropout_rate = 0
caption_tag_dropout_rate = 0
caption_dropout_every_n_epochs = 0
color_aug = false
token_warmup_min = 1
token_warmup_step = 0
[training_arguments]
output_dir = "/content/drive/MyDrive/fine_tune/output"
output_name = "70年小人书"
save_precision = "fp16"
save_n_epoch_ratio = 1
save_state = false
train_batch_size = 1
max_token_length = 225
mem_eff_attn = false
xformers = true
max_train_steps = 2500
max_data_loader_n_workers = 8
persistent_data_loader_workers = true
gradient_checkpointing = false
gradient_accumulation_steps = 1
mixed_precision = "fp16"
clip_skip = 2
logging_dir = "/content/fine_tune/logs"
log_prefix = "70年小人书"
[sample_prompt_arguments]
sample_every_n_steps = 100
sample_sampler = "ddim"
[saving_arguments]
save_model_as = "ckpt"
</p>
	  <p> These are the image generator paramaters used for each image:
	  </p>
	  <p> positive: drawing, 70年式, china, chinese, 70年小人书, black and white, beautiful</p>
	  <p> negative: color, Japan, bad, japanese, manga, anime, big breasts, deformed, ugly face, extra fingers, horrifying face</p>
	  <p> inference steps: 20, cfg scale: 7 sampler: DPM++ Karras </p>
	  <p> To work in a browser-based generator, the model was converted and set up to work with the Huggingface Diffusers library.</p>
    </div>
  );
};

export default Documentation;
