import React from "react";
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      {/* Add your footer content here */}
      <p>Original Stable Diffusion Repo</p>
	  <p>Vintage Chinese Comics Huggingface Repo</p>
	  <p>Vintage Chinese Comics on Civitai</p>
	  <p>Backend Code based on Nicholas Renotte's repo </p>
	  
    </footer>
  );
};

export default Footer;
